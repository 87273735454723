import React from "react"
import {
  Container,
  Main,
  Content,
  Contact,
  Iframely,
  Seo,
} from "../components"
import { FixedPageH1, FixedPageH2 } from "../elements"
import { graphql } from "gatsby"

const contactPage = ({ data, location }) => {
  return (
    <Container>
      <Seo
        pagetitle="お問い合わせ"
        pagedesc="お問い合わせページ"
        pagepath={location.pathname}
        pageimg={data.shop.childImageSharp.original.src}
        pageimgw={data.shop.childImageSharp.original.width}
        pageimgh={data.shop.childImageSharp.original.height}
      />
      <Iframely />
      <Main>
        <FixedPageH1><h1>お問い合わせ</h1></FixedPageH1>
        <Content>
          <Contact />
        </Content>
        <Content>
          <FixedPageH2>Maps</FixedPageH2>
          <div className="iframely-embed">
            <div
              className="iframely-responsive"
              style={{ height: 140, paddingBottom: 0 }}
            >
              <a
                href="https://www.google.com/maps/search/?api=1&query=%E3%83%9E%E3%83%AB%E3%82%B9%E3%82%A8%E4%BD%9B%E5%A3%87"
                data-iframely-url="//cdn.iframe.ly/api/iframe?url=https%3A%2F%2Fwww.google.com%2Fmaps%2Fsearch%2F%3Fapi%3D1%26query%3D%E3%83%9E%E3%83%AB%E3%82%B9%E3%82%A8%E4%BD%9B%E5%A3%87&key=bbb152fda61816746702349ee0e12e2b"
                aria-label="GoogleMaps"
              >
                {null}
              </a>
            </div>
          </div>
        </Content>
      </Main>
    </Container>
  )
}

export const query = graphql`
  query {
    shop: file(relativePath: { eq: "shop.jpg" }) {
      childImageSharp {
        original {
          height
          src
          width
        }
      }
    }
  }
`

export default contactPage
